/* eslint-disable max-len */
import MainPage from '../Containers/MainPage/MainPage';

const routesList = [
  {
    auth: false,
    component: MainPage,
    exact: true,
    name: 'mainPage',
    path: '/',
  },
];

export default routesList;
