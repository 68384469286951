import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Container, Grid, Paper } from '@material-ui/core';
import style from './styles';

const MainPage = props => {
  const { classes } = props;

  MainPage.propTypes = {};

  return (
    <>
      <Container
        maxWidth={false}
        className={classes.pageBox}
      >
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <a href="http://diputados.plndigital.net">
                <img
                  alt="bg-main-page"
                  src="./bg-main-page-diputados-new.jpg"
                  style={{ width: '100%' }}
                />
              </a>
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <Paper className={classes.paper}>
              <a href="http://aocp.plndigital.net">
                <img
                  alt="bg-main-page"
                  src="./half-aocp-sm.jpg"
                  style={{ width: '100%' }}
                />
              </a>
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <Paper className={classes.paper}>
              <a href="http://provinciales.plndigital.net">
                <img
                  alt="bg-main-page"
                  src="./half-provinciales-sm.jpg"
                  style={{ width: '100%' }}
                />
              </a>
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <Paper className={classes.paper}>
              <a href="http://distritales.plndigital.net">
                <img
                  alt="bg-main-page"
                  src="./half-distritales-sm.jpg"
                  style={{ width: '100%' }}
                />
              </a>
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <Paper className={classes.paper}>
              <a href="http://cantonales.plndigital.net">
                <img
                  alt="bg-main-page"
                  src="./half-cantonales-sm.jpg"
                  style={{ width: '100%' }}
                />
              </a>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default withStyles(style)(MainPage);
